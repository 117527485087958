import request from '@/utils/request'

export const getSchoolList = (params: {
  schoolName?: string,
  areaId?: string,
  schoolId?: string,
  schoolIds?: string[],
  keyWords?: string,
  pageSize: number,
  curPage: number
}) =>
  request({
    // 获取学校列表
    url: '/v1/SchoolList',
    method: 'GET',
    params
  })

/**
 * 注册
 * @param data 
 * @returns 
 */
export const register = (data: any) =>
  request({
    url: '/v1/School/registration',
    method: 'POST',
    data
  })

/**
 * 取消注册
 * @param data 
 * @returns 
 */
export const unregister = (data: any) =>
  request({
    url: '/v1/School/unregister',
    method: 'POST',
    data
  })



export const addSchool = (data: any) =>
  request({
    // 添加/更新学校
    url: '/v1/School',
    method: 'POST',
    data
  })

export const querySchool = (schoolId: string) =>
  request({
    //  获取学校by schoolId
    url: `/v1/School/${schoolId}`,
    method: 'GET'
  })

export const deleteSchool = (schoolId: string) =>
  request({
    // 删除学校by schoolId
    url: `/v1/School/${schoolId}`,
    method: 'DELETE'
  })

/**
 * 获取平台学校级联列表
 * @param params 
 * @returns 
 */
export const getSchoolCascadeList = () => request({
  url: '/v1/schoolCascadeList',
  method: 'get',
  headers: {
    "Content-Type": "application/json",
  },
  //params: _.merge(params, {
  //disabledTips: true
  //})
});

/**
 * 获取平台地区级联列表
 * @param params 
 * @returns 
 */
export const getAreaCascadeList = () => request({
  url: '/v1/areaCascadeList',
  method: 'get',
  headers: {
    "Content-Type": "application/json",
  },
  //params: _.merge(params, {
  //disabledTips: true
  //})
});

export const postSchoolContract = (data: any) =>
  request({
    // 保存合约
    url: '/v1/school/schoolContract',
    method: 'POST',
    data
  });

export const deleteSchoolContract = (schoolId: string) =>
  request({
    // 删除合约by schoolId
    url: `/v1/School/schoolContract/${schoolId}`,
    method: 'DELETE'
  })

/*
export const postSchoolContract = (data: any) =>
  request({
    // 添加/更新学校
    url: '/v1/SchoolContract',
    method: 'POST',
    data
  })

export const querySchoolContract = (schoolContractId: string) =>
  request({
    //  获取学校by schoolId
    url: `/v1/SchoolContract/${schoolContractId}`,
    method: 'GET'
  })

export const deleteSchoolContract = (schoolContractId: string) =>
  request({
    // 删除学校by schoolId
    url: `/v1/SchoolContract/${schoolContractId}`,
    method: 'DELETE'
  })
 */
export const getSchoolContractList = (params: {
  schoolContractId?: string,
  schoolContractNo?: string,
  pageSize: number,
  curPage: number
}) =>
  request({
    // 获取学校列表
    url: '/v1/SchoolContractList',
    method: 'GET',
    params
  })

export const getSchoolGroupList = (params: {
  schoolGroupId?: string,
  schoolGroupName?: string,
  mergeSchools?: boolean,
  pageSize: number,
  curPage: number
}) =>
  request({
    // 获取学校分组列表
    url: '/v1/schoolGroupList',
    method: 'GET',
    params
  })

export const postSchoolGroup = (data: {
  schoolGroupId: string,
  schoolGroupName: string,
  userId: string
}) =>
  request({
    // 添加/更新学校分组
    url: '/v1/schoolGroup',
    method: 'POST',
    data
  })

export const deleteSchoolGroup = (schoolGroupId: string) =>
  request({
    // 删除学校分组
    url: `/v1/schoolGroup/${schoolGroupId}`,
    method: 'DELETE'
  })

export const postSchoolSchoolGroup = (data: {
  schoolSchoolGroupId: string,
  schoolGroupId: string,
  schoolId: string
}) =>
  request({
    // 添加/更新学校分组中间表
    url: '/v1/schoolSchoolGroup',
    method: 'POST',
    data
  })

export const deleteSchoolSchoolGroup = (schoolSchoolGroupId: string) =>
  request({
    // 删除学校分组中间表
    url: `/v1/schoolSchoolGroup/${schoolSchoolGroupId}`,
    method: 'DELETE'
  })


